import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  replayIntegration,
  init as sentryInit,
} from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || null;
export const SENTRY_HOSTING_ENV =
  import.meta.env.VITE_SENTRY_HOSTING_ENV || null;

if (SENTRY_DSN && SENTRY_HOSTING_ENV) {
  sentryInit({
    dsn: SENTRY_DSN,
    environment: SENTRY_HOSTING_ENV || "development",
    initialScope: { tags: { environment: SENTRY_HOSTING_ENV } },
    beforeSend(event) {
      if (event.request?.url) {
        const url = new URL(event.request.url);
        if (
          url.protocol === "chrome-extension:" ||
          url.protocol === "moz-extension:"
        ) {
          // This error is from a browser extension, ignore it
          return null;
        }
      }
      return event;
    },
    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      browserProfilingIntegration(),
      replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
